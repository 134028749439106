import React, {
    useState, useEffect, useRef, useContext, Suspense,
} from 'react';

import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { withAnchors } from 'contexts/anchors';
import GameContext from 'contexts/game';

import getGameItemDescription from 'src/api/v4/getGameItemDescription';
import Quiz from 'src/Quiz';
import WidgetsRenderer from 'src/WidgetsRenderer';

import Button from 'atoms/Button';
import ScrollManager from 'atoms/ScrollManager';
import SeoBlock from 'atoms/SeoBlock';

import GameFeedSection from 'molecules/GameFeedSection';
import GameFeedSectionHeader from 'molecules/GameFeedSectionHeader';
import GameSliderSection from 'molecules/GameSliderSection';

import GameVideoList from 'organisms/GameVideoList';

import useGameVideoListData from 'hooks/api/useGameVideoListData';
import useReplaceLegacyRawHtmlUrls from 'hooks/useReplaceLegacyRawHtmlUrls';

import 'scss/incut.scss';
import 'scss/skin/content.scss';
import 'scss/skin/w-aside-article.scss';
import 'scss/skin/w-quote.scss';
import 'scss/table.scss';

import type { GameItemDescription } from 'entities/GameItemDescription';

const feedSections = ['requirements', 'news', 'feat', 'review', 'preview', 'secret', 'files'];

let feedsLoaded = 0;

const GameMain: React.FC = ({
    id, gameSlug, counters, tabsAmount, requirements, seo, setMainData, name,
}) => {
    const [quizData, setQuiz] = useState(null);
    const [isMainUnfolded, setFold] = useState(true);
    const [isPrerenderReady, setPrerenderReady] = useState(false);
    const [gameDescr, setGameDescr] = useState<GameItemDescription>();
    const gameVideoListData = useGameVideoListData({
        slug: gameSlug,
        shouldSkip: counters.video <= 0,
        limit: 3,
    });

    const { meta } = useContext(GameContext);

    const history = useHistory();
    const gameRef = useRef(null);

    const fetchGameItemDescription = async (options: {slug: string}) => {
        try {
            const response = await getGameItemDescription({
                pathParameters: { slug: options.slug },
            });

            setMainData({ type: 'newData', payload: response });

            setGameDescr(response);
        } catch (error) {
            // ignore
        }
    };

    useEffect(() => {
        fetchGameItemDescription({ slug: gameSlug });
    }, [gameSlug]);

    useEffect(() => {
        initQuiz();
    }, [gameDescr?.id]);

    function initQuiz(): void {
        const node = document.getElementsByClassName('quiz')[0];

        if (!node) {
            return false;
        }

        const splittedId = node.id.split('-');
        const type = splittedId[0];
        const quizId = splittedId[1];

        setQuiz({ node, id: quizId, type });
    }

    function handleFold(): void {
        setFold(true);
    }

    function handleFeedLoad(): void {
        feedsLoaded += 1;

        if (feedsLoaded >= tabsAmount - 1) {
            setTimeout(() => {
                setPrerenderReady(true);
            }, 200);
        }
    }

    const longDescription = useReplaceLegacyRawHtmlUrls(gameDescr?.long_descr);
    const description = useReplaceLegacyRawHtmlUrls(gameDescr?.descr);

    return (
        <div id="game-main" className="game-main">
            <section className="game-section game-section-description content">
                <h2 className="game-section__title game-section__title-description">
                    Описание
                    {' '}
                    {name}
                </h2>
                {description && (
                    <div
                        className="content__description"
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                )}
                {longDescription && (
                    <>
                        <div
                            className={cn('game-section__text content__text', {
                                'game-section__text_unfolded': isMainUnfolded,
                            })}
                            dangerouslySetInnerHTML={{
                                __html: `${longDescription}<div class="game-section__gradient"></div>`,
                            }}
                            ref={gameRef}
                            aria-hidden={!isMainUnfolded}
                        />
                        { isMainUnfolded && (
                            <WidgetsRenderer id={id} />
                        )}
                    </>
                )}
                {!isMainUnfolded && (
                    <Button
                        className="game-section-description_full"
                        label="Читать всё описание"
                        variant="outline-monochrome"
                        onClick={handleFold}
                    />
                )}
                {quizData && quizData.node && isMainUnfolded && gameDescr?.id && (
                    <Quiz
                        node={quizData.node}
                        id={quizData.id}
                        type={quizData.type}
                        history={history}
                        defaultMeta={meta}
                    />
                )}
                { isPrerenderReady && gameDescr?.id && id && <div id="prerenderAnchor" /> }
            </section>
            <Suspense fallback={null}>
                {feedSections.map((section) => (counters[section] > 0) && (
                    <GameFeedSection
                        key={section}
                        type={section === 'feat' ? 'articles' : section}
                        gameSlug={gameSlug}
                        onLoad={handleFeedLoad}
                        requirements={section === 'requirements' && requirements}
                        name={name}
                    />
                ))}
                {counters.video > 0 && (
                    <div className="game-section">
                        <GameFeedSectionHeader name={name} type="video" gameSlug={gameSlug} withLinks />
                        <GameVideoList data={gameVideoListData} shouldShowInModal />
                    </div>
                )}
                {counters.image > 0 && (
                    <GameSliderSection
                        key="image"
                        type="image"
                        gameSlug={gameSlug}
                        onLoad={handleFeedLoad}
                        name={name}
                    />
                )}
            </Suspense>
            {seo && (
                <SeoBlock text={seo} isLight />
            )}
            <ScrollManager id={id} />
        </div>
    );
};

export default withAnchors(GameMain);
