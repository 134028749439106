import { useState, useEffect } from 'react';

export default function useScrolledState(): boolean {
    const [isScrolled, setIsScrolled] = useState(false);

    const onScroll = (event: Event) => {
        event.currentTarget?.removeEventListener('scroll', onScroll);

        setTimeout(() => {
            setIsScrolled(true);
        }, 1000);
    };

    useEffect(() => {
        const gameCenterContainer = window.isGamecenter
            ? document.getElementById('gc-scrollbar')
            : null;

        const containerElement = gameCenterContainer || window;

        containerElement.addEventListener('scroll', onScroll, { passive: true });

        return () => containerElement.removeEventListener('scroll', onScroll);
    }, []);

    return isScrolled;
}
