import React from 'react';

import { Link, NavLink } from 'react-router-dom';

import type { MouseEvent } from 'react';
import type { NavLinkProps } from 'react-router-dom';

interface Props extends NavLinkProps {
    activeClassName?: string;
    shouldScrollTop?: boolean;
    isOuterLink?: boolean;
    targetBlank?: boolean;
    children: React.ReactNode;
    to: string;
    scrollTo?: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const CustomLink: React.FC<Props> = ({
    activeClassName,
    shouldScrollTop,
    isOuterLink,
    targetBlank,
    children,
    to,
    scrollTo,
    onClick,
    ...restProps
}) => {
    function handleClick(event: MouseEvent<HTMLAnchorElement>): void {
        if (scrollTo) {
            const node = document.getElementById(scrollTo);

            if (node) {
                node.scrollIntoView();
            }

            if (onClick) {
                onClick(event);
            }

            return;
        }

        if (shouldScrollTop && !event.metaKey && !event.ctrlKey) {
            window.scrollTo(0, 0);
        }

        if (onClick) {
            onClick(event);
        }
    }

    if (isOuterLink) {
        return (
            <a
                className={restProps.className}
                href={to}
                target={targetBlank ? '_blank' : ''}
                rel="nofollow noopener noreferrer"
                onClick={onClick}
                {...restProps}
            >
                {children}
            </a>
        );
    }

    if (activeClassName) {
        return (
            <NavLink
                to={to}
                activeClassName={activeClassName}
                onClick={handleClick}
                {...restProps}
            >
                {children}
            </NavLink>
        );
    }

    return (
        <Link
            to={to}
            onClick={handleClick}
            {...restProps}
        >
            {children}
        </Link>
    );
};

export default CustomLink;
