import { query_string } from '@vkplay/shared';

import { getCookie } from 'neurons';

const qs = query_string();

export const langsAvailable = ['en_US', 'ru_RU'];

export default function getLang(userLang) {
    const cookieLang = getCookie('amc_lang');
    const checkLang = (lang) => (lang && lang === 'ar_AA') || langsAvailable.includes(lang);

    if (checkLang(qs.lang)) {
        return qs.lang;
    }

    if (checkLang(userLang)) {
        return userLang;
    }

    if (checkLang(cookieLang)) {
        return cookieLang;
    }

    return langsAvailable[0];
}
