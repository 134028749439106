import React from 'react';

import { plural } from 'aliases';

import Button from 'atoms/Button';

import type { FC } from 'react';

interface Props {
    type:string
    name: string;
    gameSlug: string;
    isFull?: boolean;
    withLinks?: boolean;
}

const GameFeedSectionHeader: FC<Props> = ({
    type,
    name,
    gameSlug,
    isFull,
    withLinks,
}) => {
    if (isFull) {
        return null;
    }

    return (
        <div className="game-section__header">
            <h2 className="game-section__title ">
                {plural.ru[type]}
                {' '}
                {name}
            </h2>
            {withLinks && (
                <>
                    <Button
                        className="isDesktop"
                        label="Смотреть все"
                        href={`/games/${gameSlug}/${type}/`}
                        scrollTo="game-tabs"
                        variant="outline-primary"
                        role="link"
                    />
                    <Button
                        className="isMobile"
                        label="См. все"
                        href={`/games/${gameSlug}/${type}/`}
                        scrollTo="game-main"
                        variant="primary-link"
                        role="link"
                    />
                </>
            )}
        </div>
    );
};

export default GameFeedSectionHeader;
