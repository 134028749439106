import { useEffect, useRef } from 'react';

import ReactDOM from 'react-dom';

import 'scss/portal.scss';

import type { FC } from 'react';

type Props = {
    className?: string;
    disableHtmlScroll?: boolean;
}

const modalRoot = document.getElementById('modal-root');

const BODY_LOCK_CLASSNAME = 'portal__body';

const Portal: FC<Props> = ({
    className,
    children,
    disableHtmlScroll,
}) => {
    const container = useRef(document.createElement('div'));

    useEffect(() => {
        container.current.classList.add(className || 'portal');
        modalRoot.appendChild(container.current);

        if (disableHtmlScroll) {
            document.body.classList.add(BODY_LOCK_CLASSNAME);
        }

        return () => {
            modalRoot.removeChild(container.current);

            document.body.classList.remove(BODY_LOCK_CLASSNAME);
        };
    }, []);

    if (!container.current) {
        return null;
    }

    return ReactDOM.createPortal(children, container.current);
};

export default Portal;
