import React from 'react';

import cn from 'classnames';

import Button from 'atoms/Button';

import VideoItem from 'organisms/GameVideoList/VideoItem';
import VideoItemSkeleton from 'organisms/GameVideoList/VideoItemSkeleton';

import styles from './GameVideoList.module.scss';

import type { Props } from './types';
import type { FC } from 'react';

const GameVideoList: FC<Props> = ({
    className,
    data,
    skeletonItemsCount = 3,
    withPaging,
    shouldShowInModal,
}) => {
    if (!data.fetched?.results && !data.isLoading) {
        return null;
    }

    return (
        <div className={cn(className, styles.root)}>
            <div className={styles.content}>
                {data.fetched?.results.map((listItem) => (
                    <VideoItem key={listItem.id} data={listItem} shouldShowInModal={shouldShowInModal} />
                ))}
                {data.isLoading && Array.from(new Array(skeletonItemsCount)).map((_, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <VideoItemSkeleton key={index} />
                ))}
            </div>
            {withPaging && data.fetched?.next && (
                <div className="feed__more-wrap">
                    <Button
                        className="feed__more"
                        onClick={data.handleLoadMore}
                        label="Показать ещё"
                        variant="outline-primary"
                    />
                </div>
            )}
        </div>
    );
};

export default GameVideoList;
