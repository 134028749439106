const { restDomain } = window.gamesMailRuAppConfig;

interface FetcherOptions extends RequestInit {
    nativeError?: boolean;
}

interface Fetcher {
    <T>(url: string, options?: FetcherOptions & {nativeError: false | never}): Promise<T | Error>
    <T>(url: string, options?: FetcherOptions & {nativeError: true}): Promise<T>
    <T>(url: string, options?: FetcherOptions): Promise<T | Error>
}

const fetcher: Fetcher = async (url, options) => {
    const { nativeError, ...rest } = options || {};

    try {
        const response = await fetch(url, { credentials: 'include', ...rest });

        if (nativeError && !response.ok) {
            const json = await response.json();

            const data = {
                cause: {
                    status: response.status,
                    statusText: response.statusText,
                    message: json.message,
                },
            };

            throw new Error(response.statusText, data);
        }

        return await response.json();
    } catch (error) {
        if (options && 'nativeError' in options && nativeError === true) {
            throw new Error(error, { cause: error.cause });
        }

        return new Error(error);
    }
};

interface StoryData {
    slug: string;
    alias: string;
    short?: boolean;
}

// Deprecated
// Used only for review in the GameCover. Should be refactored
export function getStoryDataV3({
    slug, alias, short,
}: StoryData): Promise {
    const shortUrlPart = short ? 'short/' : '';

    return fetcher(`//${restDomain}/pc/v3/story/${alias}/${slug}/${shortUrlPart}`);
}

export function getVimeoInfo(videoSrc): Promise {
    const url = `https://vimeo.com/api/oembed.json?url=https:${encodeURI(videoSrc)}`;

    return fetcher(url, { credentials: 'omit' });
}

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
}

export function relativize(url: string): string {
    try {
        return new URL(url).pathname;
    } catch (err) {
        console.error(err);

        return url;
    }
}

export function stripTags(str): string {
    return str?.replace(/<\/?[^>]+(>|$)/g, '');
}

export function getLocalStorage(key) {
    let result = null;

    try {
        result = window.localStorage?.getItem(key);
    } catch (e) {
        console.warn(`Local Storage Error: ${e.message}`);
    }

    return result;
}

export function setLocalStorage(key, data) {
    const value = typeof data === 'string' ? data : JSON.stringify(data);

    window.localStorage?.setItem(key, value);
}

export function removeLocalStorage(key) {
    window.localStorage?.removeItem(key);
}

export function isSafari(): boolean {
    const safariRegex = /^((?!chrome|android).)*safari/i;

    return navigator.userAgent.match(safariRegex) !== null;
}

export function isGamecenter(): boolean {
    return navigator.userAgent.match(/\bDownloader\/\d+\b/) !== null;
}

// STB-приставка
export function isSTB(): boolean {
    const stbModelsRegex = /STB122A|IP6003|B866V2|Switron-i12A|Switron-i12E|GNC/gmi;

    return navigator.userAgent.match(stbModelsRegex) !== null;
}

export function getSearchParameter(name) {
    const match = RegExp(`[?&]${name}=([^&]*)`).exec(window.location.search);

    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

export function widgetAttrParse(data, widgetId) {
    let result = null;
    const id = widgetId ? ` (id: ${widgetId})` : '';

    try {
        result = JSON.parse(data);
    } catch (e) {
        console.error(`An error occurred while parsing widget attribute${id}: ${e.stack}`);
    }

    return result;
}
