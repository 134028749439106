import React, { useState, useReducer, useEffect } from 'react';

import GameContext from 'contexts/game';

import { metaData, getGameTabs } from 'aliases';
import getGameItem from 'src/api/v4/getGameItem';
import NotFound from 'templates/NotFound';

import Head from 'atoms/Head';
import Main from 'atoms/Main';
import ConditionalRenderer from 'src/atoms/ConditionalRenderer';

import Breadcrumbs from 'molecules/Breadcrumbs';
import GameFeedSection from 'molecules/GameFeedSection';
import Tabs from 'molecules/Tabs';

import GameCover from 'organisms/GameCover';
import GameMain from 'organisms/GameMain';
import GameVideoList from 'organisms/GameVideoList';
import HomeAside from 'organisms/HomeAside';

import useGameVideoListData from 'hooks/api/useGameVideoListData';
import usePageClassName from 'hooks/usePageClassName';

import { stripTags } from 'neurons';

import initJsonLd, { removeJsonLd } from '../json-ld';

import 'scss/skin/game.scss';

import type { RouteComponentProps } from 'react-router-dom';

function gameReducer(state, action) {
    switch (action.type) {
        case 'newData':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
}

const Game: React.FC<RouteComponentProps> = ({
    slug, tab,
    location: { search },
}) => {
    usePageClassName('game-page');

    const [data, setData] = useReducer(gameReducer, null);
    const [meta, setMeta] = useState(null);
    const [feedPage, setPage] = useState(1);
    const [noGame, setNoGame] = useState(false);
    const gameVideoListData = useGameVideoListData({ slug, shouldSkip: tab !== 'video' });

    useEffect(() => {
        const searchQuery = new URLSearchParams(search);
        setPage(searchQuery.get('page') || 1);
    }, [search]);

    const fetchGameItem = async (options: {slug: string}) => {
        setNoGame(false);

        try {
            const response = await getGameItem({
                pathParameters: {
                    slug: options.slug,
                },
            });

            setData({ type: 'newData', payload: response });
        } catch (error) {
            setNoGame(true);
        }
    };

    useEffect(() => {
        fetchGameItem({ slug });
    }, [slug]);

    useEffect(() => () => removeJsonLd(), []);

    useEffect(() => {
        if (!data) {
            return;
        }

        const {
            name, descr,
            canonical_url: canonical,
            date_added: published,
            picture: image,
        } = data;

        removeJsonLd();
        initJsonLd.game(data);
        initJsonLd.article({ ...data, date_published: data.date_added });

        setMeta({
            title: `${name} ${metaData.game.title} ${name}`,
            description: `${name} 🕹️ ➦ ${((!!descr && stripTags(descr)) || 'Видеоигра')}`,
            image,
            canonical,
            published,
        });
    }, [data]);

    if (noGame) {
        return <NotFound />;
    }

    if (!data?.id) {
        return null;
    }

    const {
        id, name, descr,
        counters,
        seo_text: seo,
        system_requirements_fallback: {
            min: fallbackReqMinimal,
            rec: fallbackReqRecommended,
        },
        system_requirements: systemRequirements,
    } = data;

    function countReq(): number {
        let count = 0;

        if (systemRequirements) {
            count += systemRequirements.length - 2;
        }

        if (fallbackReqMinimal) {
            count += fallbackReqMinimal.length - 1;
        }

        if (fallbackReqRecommended) {
            count += fallbackReqRecommended.length - 1;
        }

        return count;
    }

    // TODO: fix api
    const fixedCounters = {
        ...counters,
        requirements: countReq(), // проверяем наличие полей
    };

    const gameTabs = getGameTabs(slug, fixedCounters);

    if (tab && !gameTabs[tab]) {
        return <NotFound />;
    }

    const nonEmptyTabs = Object
        .values(gameTabs)
        .reduce((acc, item) => acc + Number(!!item.link), 0);

    const currentTabLabel = gameTabs[tab] && `${gameTabs[tab].label} `;

    return (
        <>
            {meta && <Head {...meta} />}
            <Breadcrumbs alias="games" name={currentTabLabel ? currentTabLabel + name : name} dark />
            <article className="game-page__wrapper">
                <ConditionalRenderer ifDesktop>
                    <h1 className="game-page__title">
                        {currentTabLabel}
                        {name}
                    </h1>
                </ConditionalRenderer>
                <GameContext.Provider value={{ ...data, meta }}>
                    <>
                        <GameCover game={data} tabLabel={currentTabLabel} description={descr} />
                        <Tabs
                            id="game-tabs"
                            tabs={Object.values(gameTabs)}
                            className="game-page__tabs"
                        />
                        <Main key="game">
                            {!gameTabs[tab] && (
                                <GameMain
                                    gameSlug={slug}
                                    counters={fixedCounters}
                                    tabsAmount={nonEmptyTabs}
                                    requirements={{
                                        str: systemRequirements,
                                        fallMin: fallbackReqMinimal,
                                        fallRec: fallbackReqRecommended,
                                    }}
                                    seo={seo}
                                    id={id}
                                    setMainData={setData}
                                    name={name}
                                />
                            ) }

                            {gameTabs[tab] && tab === 'video' && (
                                <GameVideoList
                                    className="game-section"
                                    data={gameVideoListData}
                                    skeletonItemsCount={9}
                                    withPaging
                                    shouldShowInModal
                                />
                            )}

                            {gameTabs[tab] && tab !== 'video' && (
                                <GameFeedSection
                                    name={name}
                                    type={tab}
                                    gameSlug={slug}
                                    page={feedPage}
                                    initialMeta={meta}
                                    requirements={{
                                        str: systemRequirements,
                                        fallMin: fallbackReqMinimal,
                                        fallRec: fallbackReqRecommended,
                                    }}
                                    isFull
                                />
                            )}
                        </Main>
                    </>
                </GameContext.Provider>
                <HomeAside />
            </article>
        </>
    );
};

export default React.memo(Game);
