import { useMemo } from 'react';

import { isString } from 'typeguards';
import replaceLegacyUrls from 'utils/replaceLegacyUrls';

const useReplaceLegacyRawHtmlUrls = <T>(raw: T) => useMemo<T | string>(() => {
    if (!isString(raw)) {
        return raw;
    }

    return raw.replace(/(href=")([\w/=?#:.]*)(")/gm, (match, firstPart, secondPart, thirdPart) => {
        if (!firstPart || !isString(firstPart)
            || !secondPart || !isString(secondPart)
            || !thirdPart || !isString(thirdPart)
        ) {
            return match;
        }

        return `${firstPart}${replaceLegacyUrls(secondPart)}${thirdPart}`;
    });
}, [raw]);

export default useReplaceLegacyRawHtmlUrls;
