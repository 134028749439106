import styles from './Modal.module.scss';

import type { TransitionTypes } from './types';

export const TRANSITION_TIME_IN_MS = 300;

export const OVERLAY_TRANSITION_MAP: Record<TransitionTypes, string> = {
    inTransition: styles.transitionOpacityIn,
    outTransition: styles.transitionOpacityOut,
};
