import React, {
    useState, useMemo,
} from 'react';

import cn from 'classnames';

import { metaData } from 'aliases';

import Head from 'atoms/Head';

import FeedImage from 'molecules/FeedImage';
import FeedStory from 'molecules/FeedStory';
import GameFeedSectionHeader from 'molecules/GameFeedSectionHeader';
import GameRequirements from 'molecules/GameRequirements';

import Feed from 'organisms/Feed';

function FeedStoryNoComments(props): React.FC {
    return <FeedStory {...props} noComments />;
}

const fullFeedOptions = {
    limit: 16,
    wideStep: 8,
    paging: 'combined-light',
    isWideStepDynamic: true,
    offset: 0,
    scrollToOnPaging: 'feed__title',
};

const partialFeedOptions = {
    limit: 3,
    paging: 'none',
    wideStep: 0,
};

const GameFeedSection: React.FC = ({
    type, page, name, gameSlug, isFull, initialMeta, requirements, onLoad,
}) => {
    const [isPrerenderReady, setPrerender] = useState(false);
    const [isEmpty, setEmpty] = useState(false);

    function handleFeedLoad(data): void {
        if (!data?.results?.length) {
            setEmpty(true);

            return;
        }

        setEmpty(false);
        onLoad();
        setPrerender(true);
    }

    function renderHead() {
        if (!initialMeta) {
            return null;
        }

        return (
            <Head
                {...initialMeta}
                title={`${metaData.game[type].title} ${name} ${metaData.game[type].subTitle}`}
                description={`${metaData.game[type].title} ${name} 🕹️ ➦ ${metaData.game[type].description} ${name}`}
                canonical={window.location.href}
            />
        );
    }

    const memoData = useMemo(() => {
        const isImage = type === 'image';

        return {
            key: isImage ? 'image' : 'story',
            type: type === 'articles' ? 'feat' : type,
            qOffset: isImage ? (+page - 1) * 22 : null,
            item: isImage ? FeedImage : FeedStoryNoComments,
            ...(isFull ? fullFeedOptions : partialFeedOptions),
        };
    }, [isFull, type, page]);

    if (type === 'requirements') {
        return (
            <section className={cn('game-section', `game-section-${type}`, { 'game-section_full': isFull })}>
                {renderHead()}
                <GameFeedSectionHeader name={name} type={type} gameSlug={gameSlug} isFull={isFull} />
                <GameRequirements requirements={requirements} onLoad={onLoad} />
                { isPrerenderReady && isFull && <div id="prerenderAnchor" /> }
            </section>
        );
    }

    if (isEmpty) {
        return null;
    }

    return (
        <section className={cn('game-section', `game-section-${type}`, { 'game-section_full': isFull })}>
            {renderHead()}
            <GameFeedSectionHeader name={name} type={type} gameSlug={gameSlug} isFull={isFull} withLinks />
            <Feed
                key={memoData.key}
                limit={memoData.limit}
                type={memoData.type}
                game={gameSlug}
                paging={memoData.paging}
                page={page}
                qOffset={memoData.qOffset}
                FeedItem={memoData.item}
                onLoadEnd={handleFeedLoad}
                isFirstWide={isFull}
                wideStep={memoData.wideStep}
                scrollToOnPaging="game-tabs"
                isWideStepDynamic={memoData.isWideStepDynamic}
            />
            { isPrerenderReady && isFull && <div id="prerenderAnchor" /> }
        </section>
    );
};

GameFeedSection.defaultProps = {
    onLoad: () => null,
};

export default GameFeedSection;
