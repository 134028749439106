import { useEffect, useState } from 'react';

import getGameVideoList from 'src/api/v4/getGameVideoList';

import type { FetchGameVideosOptions, ReturnType, Options } from './types';
import type { GameV4VideoList } from 'entities/GameV4VideoList';

// TODO: Hook will be removed after general state manager will be added.
const useGameVideoListData = ({ slug, shouldSkip, limit = 9 }: Options): ReturnType => {
    const [fetched, setFetched] = useState<GameV4VideoList>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchGameVideos = async (options: FetchGameVideosOptions) => {
        setIsLoading(true);

        try {
            const response = await getGameVideoList({
                pathParameters: {
                    slug: options.slug,
                },
                queryParameters: {
                    page: options.page,
                    limit: options.limit,
                },
            });

            setFetched((prevState) => (!options.shouldConcatResult
                ? response
                : {
                    ...response,
                    results: [
                        ...(prevState?.results || []),
                        ...response.results,
                    ],
                }));
        } catch (error) {
            // ignore
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (shouldSkip) {
            setFetched(undefined);

            return;
        }

        fetchGameVideos({
            slug,
            page: 1,
            limit,
        });

        return () => {
            setFetched(undefined);
        };
    }, [slug, limit, shouldSkip]);

    const handleLoadMore = () => {
        if (!fetched?.next) {
            return;
        }

        fetchGameVideos({
            slug,
            page: fetched.next,
            limit,
            shouldConcatResult: true,
        });
    };

    return {
        fetched,
        handleLoadMore,
        isLoading,
    };
};

export default useGameVideoListData;
