const idByCounter = {
    p1: '1585480',
    common: '3254134',
    'common-2': '3247783',
    nested: '3252497',
    nestedProxy: '3385732',
};

export function sendPageViewProxy({ counter, options }) {
    const tmrVkp = window._tmr_vkp_gen || (window._tmr_vkp_gen = []);

    tmrVkp.push(options ? {
        ...options,
        id: idByCounter[counter],
        type: 'pageView',
        timespent: true,
    } : {
        id: idByCounter[counter],
        type: 'pageView',
        start: (new Date()).getTime(),
        timespent: true,
    });
}

export function sendPageView({ counter, options }) {
    const tmr = window._tmr || (window._tmr = []);

    tmr.push(options ? {
        ...options,
        id: idByCounter[counter],
        type: 'pageView',
        timespent: true,
    } : {
        id: idByCounter[counter],
        type: 'pageView',
        start: (new Date()).getTime(),
        timespent: true,
    });
}
