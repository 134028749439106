import React, { useEffect, useState } from 'react';

import cn from 'classnames';

import Portal from 'atoms/Portal';

import { OVERLAY_TRANSITION_MAP, TRANSITION_TIME_IN_MS } from './constants';

import { ReactComponent as CloseSvg } from 'img/informer-close.svg';

import styles from './Modal.module.scss';

import type { Props } from './types';
import type { FC } from 'react';

const Modal: FC<Props> = ({
    active,
    onClose,
    className,
    children,
}) => {
    const [showModal, setShowModal] = useState(active);
    const [overlayTransitionClassName, setOverlayTransitionClassName] = useState('');

    useEffect(() => {
        if (active) {
            setOverlayTransitionClassName(OVERLAY_TRANSITION_MAP.inTransition);
            setShowModal(active);
        } else {
            setOverlayTransitionClassName(OVERLAY_TRANSITION_MAP.outTransition);
            setTimeout(() => setShowModal(active), TRANSITION_TIME_IN_MS);
        }
    }, [active]);

    if (showModal) {
        return (
            <Portal className={className} disableHtmlScroll>
                <div className={cn(
                    styles.root,
                    { [overlayTransitionClassName]: overlayTransitionClassName },
                )}
                >
                    <button
                        className={styles.overlay}
                        onClick={onClose}
                        aria-label="Close modal"
                    />
                    <div className={styles.content}>
                        <button
                            className={styles.closeButton}
                            onClick={onClose}
                            aria-label="Close modal"
                        >
                            <CloseSvg />
                        </button>
                        {children}
                    </div>
                </div>
            </Portal>
        );
    }

    return null;
};

export default Modal;
