import replacePathParameters from 'utils/request/replacePathParameters';
import serializeSearchParams from 'utils/serializeSearchParams';

import request from './request';

import type {
    RequestOptions,
    HTTPMethods,
    MaybeResponse,
    MaybePathParameters,
    MaybeQueryParameters,
    MaybeRequestBody,
    RequestSchema,
    CreateRequestOptions,
    URLType,
} from 'utils/request/types';

/**
 * Function creates API requests with openAPI scheme implementation in generic types:
 *
 * @example
 * import type { paths } from 'schemas/schema';
 * type GamePopularList = paths['/v4/game/popular/'];
 * const getGamePopularList = createRequest<GamePopularList>('/pc/v4/game/popular/');
 *
 * await getGamePopularList({
 *  queryParameters: { page, limit },
 *  signal,
 * });
 *
 * @example
 * import type { paths } from 'schemas/schema';
 * type StoryItem = paths['/v4/story/{type}/{slug}/'];
 * const patchStoryItem = createRequest<StoryItem, 'patch'>('/pc/v4/story/{type}/{slug}/', 'PATCH');
 *
 * await patchStoryItem({
 *  pathParameters: { type, slug },
 *  body: options.data,
 *  signal,
 * });
 */
// TODO: Make options argument optional when queryParameters and pathParameters doesn't set
const createRequest = <R extends RequestSchema, M extends HTTPMethods = 'get'>(
    url: URLType,
    method: Uppercase<HTTPMethods> = 'GET',
) => (
    options: CreateRequestOptions<MaybeQueryParameters<R, M>, MaybePathParameters<R, M>, MaybeRequestBody<R, M>>,
) => {
    const {
        pathParameters,
        queryParameters,
        ...restOptions
    } = (options || {}) as {
        pathParameters: MaybeQueryParameters<R, M>,
        queryParameters: MaybeQueryParameters<R, M>
    } & RequestOptions<MaybeRequestBody<R, M>>;

    const serializedQuery = method === 'GET' && queryParameters ? `?${serializeSearchParams(queryParameters)}` : '';

    const urlWithPathParameters = pathParameters
        ? replacePathParameters<MaybePathParameters<R, M>>(url, pathParameters)
        : url;

    const urlWithSearchParameters = `${urlWithPathParameters}${serializedQuery}`;

    return request<MaybeResponse<R, M>, MaybeRequestBody<R, M>>(urlWithSearchParameters, { method, ...restOptions });
};

export default createRequest;
