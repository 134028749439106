import { stripTags } from 'neurons';

const { desktopHost, pathPrefix } = window.gamesMailRuAppConfig;

const schemaTypes = {
    news: 'NewsArticle',
    review: 'Review',
    article: 'Article',
    game: 'VideoGame',
    video: 'VideoObject',
    profile: 'Person',
};

const organization = 'VK Play Медиа';
const altName = 'vkplay';
const url = 'https://media.vkplay.ru/';
const defaultLogo = 'https://vkplay.ru/hotbox/images/vk_400x400.png';

const orgSchema = {
    '@type': 'Organization',
    name: organization,
    alternateName: altName,
    // eslint-disable-next-line max-len
    description: 'Сайт о компьютерных, консольных и мобильных играх: новости, статьи, рецензии, прохождения, читы, видео и многое другое.',
    url,
    sameAs: `https://${desktopHost}/${pathPrefix}`,
    logo: {
        '@type': 'ImageObject',
        url: defaultLogo,
        width: 400,
        height: 400,
    },
};

function getData(type, articleData, additionalData) {
    const {
        canonical_url, name, picture, date_published, date_modified, author, descr,
    } = articleData;

    const description = descr ? /<p>(.*?)<\/p>/g.exec(descr)[1] : '';

    const schema = {
        '@context': 'https://schema.org',
        '@type': schemaTypes[type],
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': canonical_url,
        },
        headline: name,
        image: picture,
        datePublished: date_published,
        dateModified: date_modified,
        inLanguage: 'ru',
        author: {
            '@type': author ? 'Person' : 'Organization',
            name: (author && author.nick) || organization,
        },
        publisher: orgSchema,
        description,
        ...additionalData,
    };

    return JSON.stringify(schema, null, 2);
}

const initJsonLd = {
    site: () => {
        const schema = JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            url: `https://${desktopHost}/${pathPrefix}`,
            potentialAction: {
                '@type': 'SearchAction',
                target: `https://${desktopHost}/${pathPrefix}search/?q={search_term_string}`,
                'query-input': 'required name=search_term_string',
            },
        }, null, 2);

        initJsonLd.appendToHead(schema);

        return schema;
    },
    app: () => {
        const schema = JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebApplication',
            name: organization,
            applicationCategory: 'EntertainmentApplication',
            operatingSystem: ['Windows', 'macOS', 'Linux'],
            image: {
                '@type': 'ImageObject',
                url: defaultLogo,
                width: 400,
                height: 400,
            },
            offers: {
                '@type': 'Offer',
                price: 0,
                priceCurrency: 'RUB',
            },
        }, null, 2);

        initJsonLd.appendToHead(schema);

        return schema;
    },
    organization: () => {
        const schema = JSON.stringify({
            '@context': 'https://schema.org',
            ...orgSchema,
        }, null, 2);

        initJsonLd.appendToHead(schema);

        return schema;
    },
    news: (articleData) => {
        const schema = getData('news', articleData);

        initJsonLd.appendToHead(schema);

        return schema;
    },

    review: (articleData, gameData) => {
        let additionalData = {};

        if (gameData) {
            const {
                name, rating, canonical_url, name_alt, picture, platforms, genre,
            } = gameData;

            additionalData = {
                itemReviewed: {
                    '@type': 'VideoGame',
                    name,
                    sameAs: name_alt || canonical_url,
                    applicationCategory: 'Game',
                    applicationSubCategory: genre ? genre.reduce((acc, item) => {
                        acc.push(item.name);

                        return acc;
                    }, []) : 'Игра',
                    gamePlatform: platforms,
                    operatingSystem: platforms,
                    url: canonical_url,
                    image: {
                        '@type': 'ImageObject',
                        url: picture,
                    },
                },
                reviewRating: {
                    '@type': 'Rating',
                    ratingValue: +rating || 1,
                    worstRating: 1,
                    bestRating: 10,
                },
            };
        }

        const schema = getData('review', articleData, additionalData);

        initJsonLd.appendToHead(schema);

        return schema;
    },

    article: (articleData) => {
        const schema = getData('article', articleData);

        initJsonLd.appendToHead(schema);

        return schema;
    },

    game: (gameData) => {
        const {
            name,
            canonical_url,
            rating_ugc,
            platforms,
            developer_list,
            publisher_list,
            system_requirements,
            counters,
            genre,
            date_added,
        } = gameData;

        const author = developer_list?.length
            ? {
                '@type': 'Organization',
                name: developer_list[0].name,
            }
            : 'unknown';

        const publisher = publisher_list?.length
            ? {
                '@type': 'Organization',
                name: publisher_list[0].name,
            }
            : 'unknown';

        const rating = +rating_ugc > 0
            ? {
                '@type': 'AggregateRating',
                ratingValue: rating_ugc,
                ratingCount: counters.review,
                worstRating: 1,
                bestRating: 10,
            }
            : '';

        const reqs = system_requirements && JSON.parse(system_requirements);

        let memoryRequirements = '';
        let processorRequirements = '';
        let storageRequirements = '';

        if (reqs) {
            if (reqs.min) {
                memoryRequirements = reqs.min.ram || '';
                processorRequirements = reqs.min.cpu || '';
                storageRequirements = reqs.min.hdd || '';
            }

            if (reqs.rec) {
                memoryRequirements = reqs.rec.ram || reqs.min?.ram || '';
                processorRequirements = reqs.rec.cpu || reqs.min?.cpu || '';
                storageRequirements = reqs.rec.hdd || reqs.min?.hdd || '';
            }

            processorRequirements = processorRequirements.replace(/\n/, ' или ');
        }

        const additionalData = {
            name,
            url: canonical_url,
            applicationCategory: 'Game',
            applicationSubCategory: genre ? genre.reduce((acc, item) => {
                acc.push(item.name);

                return acc;
            }, []) : 'Игра',
            datePublished: date_added,
            aggregateRating: rating,
            gamePlatform: platforms,
            operatingSystem: platforms,
            publisher,
            creator: author,
            author,
            memoryRequirements,
            processorRequirements,
            storageRequirements,
        };

        const schema = getData('game', gameData, additionalData);

        initJsonLd.appendToHead(schema);

        return schema;
    },

    video: ({
        name, descr, picture, date_published, date_modified, video_url, iframe_url,
    }) => {
        const schema = JSON.stringify({
            '@context': 'https://schema.org',
            '@type': schemaTypes.video,
            name,
            description: stripTags(descr),
            thumbnailUrl: picture && picture.includes('games.mail.ru') ? picture : `https://games.mail.ru${picture}`,
            datePublished: date_published,
            dateModified: date_modified,
            uploadDate: date_published,
            contentUrl: video_url,
            embedUrl: iframe_url,
        }, null, 2);

        initJsonLd.appendToHead(schema);

        return schema;
    },

    profile: ({
        url: _url, name, description, image, id,
    }) => {
        const schema = JSON.stringify({
            '@context': 'https://schema.org',
            '@type': schemaTypes.profile,
            url: _url,
            name,
            description,
            image,
            memberOf: organization,
            identifier: id,
        }, null, 2);

        initJsonLd.appendToHead(schema);

        return schema;
    },

    appendToHead: (schema, kind) => {
        const scriptTag = document.createElement('script');
        scriptTag.type = 'application/ld+json';

        if (kind) {
            scriptTag.dataset.kind = kind;
        }

        scriptTag.innerHTML = schema;

        document.head.appendChild(scriptTag);
    },
};

export function removeJsonLd(kind) {
    const selector = kind
        ? `script[type="application/ld+json"][data-kind=${kind}]`
        : 'script[type="application/ld+json"]';

    const LDTags = document.querySelectorAll(selector);

    // eslint-disable-next-line no-restricted-syntax
    for (const tag of LDTags) {
        tag.remove();
    }
}

export default initJsonLd;
