import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import Picture from 'atoms/Picture';

import Modal from 'molecules/Modal';
import VideoFrame from 'molecules/VideoFrame';

import styles from './GameVideoList.module.scss';

import type { VideoItemProps } from './types';
import type { FC, MouseEvent } from 'react';

const VideoItem: FC<VideoItemProps> = ({ data, shouldShowInModal }) => {
    const [isModalActive, setIsModalActive] = useState(false);

    const handleOpenModal = (event: MouseEvent<HTMLAnchorElement>) => {
        if (!shouldShowInModal) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        setIsModalActive(true);
    };

    const handleCloseModal = () => {
        setIsModalActive(false);
    };

    return (
        <>
            <Link to={`/video/${data.slug}/`} onClick={handleOpenModal} className={styles.item}>
                <Picture classNameWrapper={styles.picture} src={data.preview} />
                <p className={styles.title}>{data.title}</p>

            </Link>
            {shouldShowInModal && (
                <Modal onClose={handleCloseModal} active={isModalActive}>
                    <div className={styles.modalContent}>
                        <VideoFrame url={data.url} title={data.title} />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default VideoItem;
