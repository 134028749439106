/**
 * Slash is possible to add to the end of url in following cases:
 * - There is no slash standing as last symbol of URL.
 * - There is no file extension (or there is no dot inside the last section of the path).
 * - There is no parameter (even empty one — a single ? at the end of URL).
 * - There is no link to a fragment (even empty one — a single # mark at the end of URL).
 */

const appendSlashToUrlIfIsPossible = (url: string) => {
    /** Removing empty parameter or fragment so the URL will always have slash if possible */
    const cleanUrl = url.replace(/#$/g, '').replace(/\?$/g, '');

    const absoluteUrl = cleanUrl.startsWith('/') ? `${window.location.origin}${cleanUrl}` : cleanUrl;

    const parsedUrl = new URL(absoluteUrl);

    /** There are directories with dots in the last section of the path,
     * so we can only hope that the file extension being in used (if any) is a common one */
    const noFileExtension = !/\.(htm|html|jpg|png|gif|pdf|php|doc|docx)$/.test(parsedUrl.pathname);

    const noParameter = !parsedUrl.search;
    const noLinkToFragment = !parsedUrl.hash;

    /** All checks above cannot guarantee that there is no '?' or '#' symbol at the end of URL.
     * It is required to be checked manually */
    const noTrailingSlashAlready = !/\/$/.test(parsedUrl.href);

    const slashAppendingIsPossible = noFileExtension && noParameter && noLinkToFragment && noTrailingSlashAlready;

    return slashAppendingIsPossible ? `${parsedUrl.href}/` : parsedUrl.href;
};

export default appendSlashToUrlIfIsPossible;
