import React from 'react';

import cn from 'classnames';

import styles from './VideoFrame.module.scss';

import type { Props } from './types';
import type { FC } from 'react';

const VideoFrame: FC<Props> = ({
    className,
    url,
    title,
    allowFullScreen = true,
}) => (
    <div className={cn(className, styles.root)}>
        <iframe className={styles.iframe} src={url} title={title} allowFullScreen={allowFullScreen} />
    </div>
);

export default VideoFrame;
