import appendSlashToUrlIfIsPossible from 'utils/appendSlashToUrlIfIsPossible';

const replaceLegacyUrls = (url: string) => {
    let result = url;

    // Replace old p1 (/pc/) prefix at the start of url
    result = result.replace(/^\/pc\//gmi, '/');

    // Replace games.mail.ru or games.mail.ru/pc with media.vkplay.ru
    result = result.replace(
        /^((?:https?:)?\/\/)games\.mail\.ru(?:\/pc)?/gmi,
        (_, match) => `${match}media.vkplay.ru`,
    );

    // Add trailing slash to the end
    result = appendSlashToUrlIfIsPossible(result);

    return result;
};

export default replaceLegacyUrls;
