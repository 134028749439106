import React from 'react';

import styles from './GameVideoList.module.scss';

const VideoItemSkeleton = () => (
    <div className={styles.skeletonItem}>
        <div className={styles.picture} />
        <div className={styles.title} />
    </div>
);

export default VideoItemSkeleton;
